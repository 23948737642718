var $cursor;
$(function () {

  function updateCursor(event) {
    var eventDoc, doc, body;
    event = event || window.event; // IE-ism
    // If pageX/Y aren't available and clientX/Y are,
    // calculate pageX/Y - logic taken from jQuery.
    // (This is to support old IE)
    if (event.pageX == null && event.clientX != null) {
      eventDoc = (event.target && event.target.ownerDocument) || document;
      doc = eventDoc.documentElement;
      body = eventDoc.body;

      event.pageX = event.clientX +
        (doc && doc.scrollLeft || body && body.scrollLeft || 0) -
        (doc && doc.clientLeft || body && body.clientLeft || 0);
      event.pageY = event.clientY +
        (doc && doc.scrollTop || body && body.scrollTop || 0) -
        (doc && doc.clientTop || body && body.clientTop || 0);
    }
    cursorY = event.clientY;
    cursorX = event.pageX;
    setTimeout(function () {
      $cursor.css({
        'top': cursorY,
        'left': cursorX
      })
    }, 60)
  }

  $cursor = $('<div>', {
    'id': 'cursor-e'
  });

  body.append($cursor).addClass('cursor-custom');
  var ver = 0;
  $('a, button:not(.expand), .process-step-header, .product-grid-header header, .list-faqs-item header,input[type=submit]').hover(function () {
    ver++;
    if (ver > 2) {
      ver = 0;
    }
    $cursor.addClass('hover').attr('data-ver', 'ver-' + ver);
  }, function () {
    $cursor.removeClass('hover');
  });
  //Added event for submit button because jQuery handles events for dynamically generated elements differently
  $(document).on("mouseenter", ".newsletter .submit", function () {
    $cursor.addClass('hover').attr('data-ver', 'ver-' + ver);
  }).on("mouseleave", ".newsletter .submit", function() {
    $cursor.removeClass("hover");
  })
  $('.bg-inv').hover(function () {
    $cursor.addClass('invert');
  }, function () {
    $cursor.removeClass('invert');
  });
  $('.bg-inv .bg-inv').hover(function () {
    $cursor.removeClass('invert');
  }, function () {
    $cursor.addClass('invert');
  });
  $('.bg-gold').hover(function () {
    $cursor.addClass('alt');
  }, function () {
    $cursor.removeClass('alt');
  });
  // // HIDE FOR IFRAMES
  // $('iframe, .embed-social-item').hover(function(){
  //   $cursor.stop().hide();
  // },function(){
  //   $cursor.fadeIn(600);
  // });
  // UPDATE ON MOUSEMOVE
  $(document).on('mousemove', updateCursor);
  // document.onmousemove = updateCursor;

});