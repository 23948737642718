$(function(){
	$('.slider-product-slider').each(function(){
		var $prev = $('<button>',{class:'slider-prev'}),
			$next = $('<button>',{class:'slider-next'}),
			$slider = $(this).flickity({
				wrapAround: true,
				autoPlay: false,
				adaptiveHeight: false,
				imagesLoaded: true,
				contain: true,
				freeScroll: false,
				responsive: true,
				pageDots:true,
				cellAlign: 'left',
				prevNextButtons: false
			});
		$prev.add($next).appendTo($(this));
		$prev.click(function(){
			$slider.flickity('previous');
		});
		$next.click(function(){
			$slider.flickity('next');
		});
	});
	$('.slider-product-slide .expand').click(function(){
		var $this = $(this),
			cont = $(this).closest('.product-detail').find('main');
		if($this.hasClass('active')){
			cont.slideUp();
			$this.removeClass('active');
		} else{
			cont.slideDown();
			$this.addClass('active');
		}
	});
});