$(function(){
	$('.process-expanded').each(function(){
		var scope = $(this).find('.inner'),
			items = $(this).find('.process-expanded-section'),
			fig = $('<figure>',{class:'process-figure'});
		scope.prepend(fig);
		items.each(function(i){
			var item = $(this),
				img = $(this).find('figure.hero').clone();
			fig.append(img.hide());
			body.on('scrolld',function(){
				if(item.offset().top < winH * 0.6 && item.offset().top + item.outerHeight() > winH * 0.2){
					item.addClass('scroll-vis');
					img.fadeIn()
				} else{
					item.removeClass('scroll-vis');
					img.fadeOut()
				}
			});
		});
	})
});