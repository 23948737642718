$(function(){
	$('.list-faqs').each(function(){
		var scope = $(this),
			header = $(this).find('header');
		header.click(function(){
			if($(this).hasClass('active')){
				$(this).removeClass('active').next('main').slideUp();
			} else{
				header.each(function(){
					$(this).removeClass('active').next('main').slideUp();
				});
				$(this).addClass('active').next('main').slideDown();
			}
		});
	});
});