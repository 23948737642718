$(function(){
	$('.enquire-main .form-field.product').each(function(){
		var scope = $(this),
			field = $(this).find('input');
		if(getParameterByName('quote')){
			var quote = localStorage.getItem('myQuote');
			field.val(quote);
			scope.show();
		}
	});
});

function getParameterByName(name, url) {
    if (!url) url = window.location.href;
    name = name.replace(/[\[\]]/g, '\\$&');
    var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
        results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, ' '));
}