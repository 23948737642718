$(function(){
    $('nav#nav-mobile').mmenu({
	    "extensions": [
              "position-right",
              "theme-dark"
           ]
    });
    var $menu = $('nav#nav-mobile').data("mmenu");

    $('#nav-toggle').click(function(){
       $menu.open();
    });
});