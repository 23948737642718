$(function(){
	$('.product-calculator').each(function(){
		var scope = $(this),
			dimension = scope.find('.dimension'),
			price = scope.find('.result-panel-price'),
			name = scope.attr('data-product'),
			rate = scope.attr('data-rate'),
			addon = scope.find('.calc-add-on'),
			bill = scope.find('.bill-items'),
			w = scope.find('#calc-dim-w'),
			d = scope.find('#calc-dim-d'),
			total;

		function updateAddOns(){
			scope.find('.bill-item-add').remove();
			addon.each(function(){
				var el = $(this);
				if($(this).hasClass('active')){
					var item = $('<li>',{class:'bill-item-add'}),
						span = $('<span>',{class:'bill-item-label'}),
						strong = $('<strong>',{class:'bill-item-qty'});
					span.text(el.attr('data-title')).appendTo(item);
					strong.text(el.attr('data-price')).appendTo(item);
					bill.append(item);
					total = total + parseFloat(el.attr('data-price'));
					item.click(function(){
						el.click();
					});
				}
			});
		}

		function updateQuote(){
			localStorage.clear();
			var quote = name;
			quote += ' ('+w.val()+'m x '+d.val()+'m';
			$('.bill-item-add').each(function(){
				quote += ', + '+$(this).find('.bill-item-label').text();
			});
			quote+=')';
			localStorage.setItem('myQuote',quote);
			console.log(localStorage.getItem('myQuote'));
		}
		
		function updatePrice(){
			total = w.val() * d.val() * rate;
			scope.find('#calc-width').text(w.val())
			scope.find('#calc-depth').text(d.val())
			updateAddOns();
			price.text('Â£'+transformPrice(total))
			updateQuote();
		}	

		dimension.each(function(){
			var input = $(this).find('input[type=number]'),
				range = $(this).find('input[type=range]');
			range.on('change',function(){
				input.val($(this).val());
				input.trigger('change');
			}).trigger('change');
			range.on('input',function(){
				input.val($(this).val());
				input.trigger('change');
			}).trigger('input');
			input.on('change',function(){
				updatePrice()
			});
			updatePrice();
		});

		addon.click(function(){
			$(this).toggleClass('active');
			updatePrice();
		});
	});
});

function transformPrice(val){
	var price = CurrencyFormatted(val);
	price = CommaFormatted(price);
	return price;
}

function CurrencyFormatted(amount) {
	var i = parseFloat(amount);
	if(isNaN(i)) { i = 0.00; }
	var minus = '';
	if(i < 0) { minus = '-'; }
	i = Math.abs(i);
	i = parseInt((i + .005) * 100);
	i = i / 100;
	s = new String(i);
	if(s.indexOf('.') < 0) { s += '.00'; }
	if(s.indexOf('.') == (s.length - 2)) { s += '0'; }
	s = minus + s;
	return s;
}

function CommaFormatted(amount) {
	var delimiter = ","; // replace comma if desired
	var a = amount.split('.',2)
	var d = a[1];
	var i = parseInt(a[0]);
	if(isNaN(i)) { return ''; }
	var minus = '';
	if(i < 0) { minus = '-'; }
	i = Math.abs(i);
	var n = new String(i);
	var a = [];
	while(n.length > 3) {
		var nn = n.substr(n.length-3);
		a.unshift(nn);
		n = n.substr(0,n.length-3);
	}
	if(n.length > 0) { a.unshift(n); }
	n = a.join(delimiter);
	if(d.length < 1) { amount = n; }
	else { amount = n + '.' + d; }
	amount = minus + amount;
	return amount;
}