$(function(){
	$('.section-process-min').each(function(){
		var scope = $(this).children('.inner'),
			items = $(this).find('.process-step'),
			header = $(this).find('.process-step-header'),
			fig = $('<figure>',{class:'process-figure'});
		$(this).addClass('process-d');
		scope.append(fig);
		header.click(function(){
			var step = $(this).closest('.process-step'), 
				cont = $(this).next('main').clone();
			if(step.hasClass('active') && !fig.is(':visible')){
				step.removeClass('active').find('main').slideUp();
			} else{
				items.removeClass('active').find('main').slideUp();
				step.addClass('active').find('main').slideDown();
			}
			console.log(cont.html())
			fig.html(cont.show());
		});
		if(fig.is(':visible')){
			header.first().click();
		}
	});
});