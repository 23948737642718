var scrollPos;
var scrollMoving = false;
function SmoothScroll(target, speed, smooth) {
	if (target == document)
		target = (document.documentElement || document.body.parentNode || document.body) // cross browser support for document scrolling
	scrollPos = target.scrollTop
	target.addEventListener('mousewheel', scrolled, { passive: false })
	target.addEventListener('DOMMouseScroll', scrolled, { passive: false })

	function scrolled(e) {
		if(!body.hasClass('menu-open')){
			e.preventDefault(); // disable default scrolling

			var delta = normalizeWheelDelta(e)

			scrollPos += -delta * speed
			scrollPos = Math.max(0, Math.min(scrollPos, target.scrollHeight - target.clientHeight + 20)) // limit scrolling
			if (!scrollMoving) update()
		}
	}

	function normalizeWheelDelta(e){
		if(e.detail){
			if(e.wheelDelta)
				return e.wheelDelta/e.detail/40 * (e.detail>0 ? 1 : -1) // Opera
			else
				return -e.detail/3 // Firefox
		}else
			return e.wheelDelta/120 // IE,Safari,Chrome
	}

	function update() {
		scrollMoving = true
		var delta = (scrollPos - target.scrollTop) / smooth
		target.scrollTop += delta
		if (Math.abs(delta) > 0.5){
			requestFrame(update)
		} else{
			scrollMoving = false
		}
	}

	var requestFrame = function() { // requestAnimationFrame cross browser
		return (
			window.msRequestAnimationFrame ||
			window.requestAnimationFrame ||
			window.webkitRequestAnimationFrame ||
			window.mozRequestAnimationFrame ||
			window.oRequestAnimationFrame ||
			function(func) {
				window.setTimeout(func, 1000 / 50);
			}
		);
	}()
}

var animScroll = function(target,durat){
	if (typeof durat == 'undefined') {
	    durat = dur*2;
	}
	disableScroll = true;
	$('html,body').animate({'scrollTop':target},durat);
	setTimeout(function(){
		disableScroll = false;
		$(window).trigger('scroll');
		scrollPos = $(window).scrollTop();
	},durat);
}

$(function(){
	// INIT SMOOTH SCROLL
	if(checkUserAgent() === 0){
		var smoothScrollingInst = new SmoothScroll(document.body,120,12);
	}
})